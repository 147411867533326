<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>

    <b-modal
      class="p-2"
      id="confirm-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <img
        class="d-block mx-auto my-3"
        src="../../assets/Depreciation/pause_dp.svg"
        alt=""
      />
      <p class="text-center">
        {{
          $t("depreciation.pause_dialog", {
            date: moment(pauseDate).format("DD/MM/YYYY"),
          })
        }}
      </p>
      <div class="row justify-content-center gapx-2">
        <button class="cancel-button" @click="$bvModal.hide('confirm-modal')">
          {{ $t("btn.canc") }}
        </button>
        <button
          class="confirm-button confirm-button-red"
          @click="actionToAsset"
        >
          {{ $t("btn.conf") }}
        </button>
      </div>
    </b-modal>

    <b-modal
      class="p-2"
      id="pause-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <p>{{ $t("depreciation.pause_date_select") }}</p>
      <date-picker
        class="inline-block h-full"
        locale="en"
        v-model="pauseDate"
        :max-date="new Date()"
      >
        <template v-slot="{ inputValue, togglePopover }">
          <div
            class="w-100 input-arrow me-3 my-1 d-flex align-items-center"
            @click="togglePopover"
          >
            {{ inputValue | formatDate }}
          </div>
        </template>
      </date-picker>
      <div class="row justify-content-center gapx-2">
        <button class="cancel-button" @click="$bvModal.hide('pause-modal')">
          {{ $t("btn.canc") }}
        </button>
        <button class="confirm-button" @click="$bvModal.show('confirm-modal')">
          {{ $t("btn.conf") }}
        </button>
      </div>
    </b-modal>

    <b-modal
      class="p-2"
      id="cancel-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <img
        class="d-block mx-auto my-3"
        src="../../assets/Depreciation/reject.svg"
        alt=""
      />
      <p class="text-center">
        {{ $t("depreciation.cancel_activate_dialog") }}
      </p>
      <p class="text-center" style="font-size: 12px; color: #7b7b7b">
        {{ $t("depreciation.cancel_activate_message") }}
      </p>
      <div class="row justify-content-center gapx-2">
        <button class="cancel-button" @click="$bvModal.hide('cancel-modal')">
          {{ $t("btn.canc") }}
        </button>
        <button class="confirm-button confirm-button-red" @click="cancelAction">
          {{ $t("btn.conf") }}
        </button>
      </div>
    </b-modal>

    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center flex-wrap">
            <button
              class="btn-outline danger-btn me-3 my-1"
              :disabled="!selectedAssetLength"
              @click="$bvModal.show('pause-modal')"
            >
              <p>{{ $t("depreciation.pause_dp") }}</p>
            </button>
          </div>
          <div class="d-flex">
            <div
              class="search-box d-flex"
              style="border-radius: 10px; margin-right: 10px"
            >
              <img src="@/assets/search-white.svg" width="20px" />
              <input
                type="text"
                v-debounce:700ms="searchByText"
                :placeholder="$t('home.search')"
                v-model="searchText"
              />
            </div>
            <!-- Add -->
            <button
              class="filter"
              style="border: none"
              type="button"
              id="button-addon2"
              @click="showFilter = true"
            >
              <img src="../../assets/filter-white.svg" />
            </button>
          </div>

          <MainAssetFilter
            v-show="showFilter"
            @filterOptions="saveFilter"
            @closeFilter="showFilter = false"
          />
        </div>
        <MasterTable
          @selected-assets="setSelectedAsset"
          :assetData="getAllAssets"
          :otherColumn="getAllAssetOtherColumn"
          :page="currentPage"
          @cancel-action="showCancelAction"
        />
        <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
            @change="loadAssetData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import { authHeader } from "../../store/actions";
import Loading from "vue-loading-overlay";
import moment from "moment";
import MasterTable from "../../components/Table/Depreciation/MasterDepreciationTable.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { mapGetters } from "vuex";
import baseUrl from "../../util/backend";
import MainAssetFilter from "../../components/MainAssetFilter.vue";
export default {
  name: "DeactivateAsset",
  components: {
    MasterTable,
    DatePicker,
    Loading,
    MainAssetFilter,
  },
  data() {
    return {
      isLoading: true,
      company_id: null,
      pauseDate: moment().format(),
      selectedAsset: [],
      searchText: "",
      filterOptions: {},
      action: "deactivate",
      currentPage: 1,
      totalRows: 1,
      perPage: 50,
      toCancelId: null,
      showFilter: false,
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      getAllAssets: "getAllAssetsStore",
      getAllAssetOtherColumn: "getAllAssetOtherColumn",
      assets_count: "assets_count",
      companyName: "companyName",
    }),
    selectedAssetLength() {
      return this.selectedAsset.length > 0;
    },
  },

  methods: {
    moment,
    async onExport(isMail) {
      this.isLoading = true;
      try {
        const res = await this.$store.dispatch("ExportAsset", {
          depreciation_status: "active",
          is_email: isMail,
          company_id: this.company_id,
          filterOptions: this.filterOptions,
          filename: `${this.$t("asset.as_regdoc")}_${
            this.companyName
          }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`,
          type: "docRegisAsset",
          selected_assets: [],
        });
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async actionToAsset() {
      this.isLoading = true;
      const assetMapID = this.selectedAsset.map((asset) => asset.id_asset);
      try {
        await axios.put(
          `${baseUrl()}asset/pauseAsset/${this.company_id}`,
          {
            id_asset_list: assetMapID,
            deactivate_at: moment(this.pauseDate)
              .startOf("d")
              .toISOString(),
          },
          authHeader()
        );
        Swal.fire({
          icon: "success",
          text: this.$t("depreciation.success_request"),
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      } catch (err) {
        if (err.response) {
          if (
            err.response.data.message ===
            "Some assets not found / can't calculate depreciation"
          ) {
            alert(this.$t("depreciation.depreciation_error_dialog"));
          } else if (
            err.response.data.message ===
            "Some assets are not active on the deactivate date"
          ) {
            alert(this.$t("depreciation.deactivate_error_dialog"));
          } else {
            alert(err.response.data.message);
          }
        } else {
          alert(err.message);
        }
      }
      this.$bvModal.hide("pause-modal");
      this.$bvModal.hide("confirm-modal");
      this.isLoading = false;
      this.loadAssetData();
    },
    showCancelAction(pauseId) {
      this.toCancelId = pauseId;
      this.$bvModal.show("cancel-modal");
    },
    async cancelAction() {
      this.isLoading = true;
      try {
        const res = await axios.put(
          `${baseUrl()}asset/requestcancelactivate/${this.company_id}`,
          {
            id_pause_asset_list: [this.toCancelId],
          },
          authHeader()
        );
        Swal.fire({
          icon: "success",
          text: this.$t("depreciation.success_request"),
          showConfirmButton: false,
          showCancelButton: false,
          timer: 2000,
        });
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
      this.$bvModal.hide("cancel-modal");
      this.loadAssetData();
    },
    setSelectedAsset(value) {
      this.selectedAsset = value;
    },

    async searchByText() {
      this.filterOptions.text = this.searchText;
      this.currentPage = 1;
      this.loadAssetData();
    },
    async loadAssetData() {
      this.selectedAsset = [];
      this.isLoading = true;
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      await this.$store.dispatch("filterAllAsset_pagination", {
        depreciation_status: ["active", "expire"],
        filterOptions: this.filterOptions,
        saveToStore: false,
        countingStatus: this.countingStatus,
        page: this.currentPage,
        company_id: this.company_id,
        limit: this.perPage,
        is_requesting: "null",
        calculate_depreciation: true, // true send back only asset that can calculate depreciation
      });
      this.totalRows = this.assets_count;
      this.isLoading = false;
    },
    saveFilter(val) {
      this.showFilter = false;
      this.filterOptions = val;
      if (val.is_clear_filter) {
        this.searchText = "";
      }
      this.searchByText();
    },
  },
  mounted() {
    this.loadAssetData();
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
  &:disabled {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: default !important;
  }
}

.form-control {
  min-width: 200px;
  width: 200px;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

.input-arrow {
  user-select: none;
  cursor: pointer;
  min-width: 200px;
  width: 200px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 0.375rem 0.75rem;
  color: #212529;
  border-radius: 0.25rem;
  background-image: url("../../assets/down-arrow-black.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 97% 50% !important;
  padding-right: 40px !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.confirm-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  border-radius: 10px;
  border: none;

  &-red {
    background: #ed1616;
  }
}

.cancel-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: #888;
  border-radius: 10px;
  border: none;
}

/*
  Animation
*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#button-addon2 {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  width: 45px;
  height: 45px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  // border: 1px solid #7c7c7c;
  img {
    width: 40px;
    padding: 0.3rem !important;
  }
}
</style>
